@use "/src/styles/constants" as constant;

.get-more {
  margin-top: 104px;
  @media (min-width: constant.$mobile-size) {
    margin-top: 176px;
  }
  @media (min-width: 901px) {
    margin-top: 200px;
  }

  &-container {
    .get-more-content-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      gap: 38px;
      @media (min-width: constant.$mobile-size) {
        gap: 58px;
      }
      @media (min-width: 1235px) {
        gap: 24px;
        flex-wrap: nowrap;
        justify-content: unset;
      }

      .get-more-image-block {
        width: 100%;
        @media (min-width: 901px) {
          width: unset;
        }

        .image-wrapper {
          background: linear-gradient(90deg, #EAEBEC 0%, rgba(234, 235, 236, 0) 100%);
          border-radius: 16px;
          max-width: 704px;
          height: 138px;
          position: relative;
          @media (min-width: constant.$mobile-size) {
            height: 282px;
          }
          @media (min-width: 901px) {
            max-width: unset;
            width: 816px;
            height: 328px;
          }

          .leaflet {
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 16px;
            width: 90px;
            @media (min-width: 395px) {
              width: 120px;
            }
            @media (min-width: constant.$mobile-size) {
              width: 210px;
            }
            @media (min-width: 901px) {
              width: unset;
            }
          }

          .phones-image {
            position: absolute;
            right: 56px;
            top: -18px;
            display: flex;
            gap: 24px;
            @media (min-width: constant.$mobile-size) {
              top: -36px;
              gap: 40px;
            }
            @media (min-width: 901px) {
              top: -42px;
            }

            .first-phone-image {
              width: 84px;
              @media (min-width: constant.$mobile-size) {
                width: 170px;
              }
              @media (min-width: 901px) {
                width: 198px;
              }
            }

            .second-phone-image {
              width: 78px;
              @media (min-width: constant.$mobile-size) {
                width: 160px;
              }
              @media (min-width: 901px) {
                width: 186px;
              }
            }
          }
        }
      }

      .get-more-links {
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        @media (min-width: 901px) {
          gap: 32px;
        }
        @media (min-width: 1235px) {
          margin: 0;
          max-width: 576px;
          text-align: left;
          align-items: flex-start;
        }
      }
    }
  }
}
