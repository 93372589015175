@use "/src/styles/constants" as constant;
@use "/src/styles/mixins" as mixin;

.become-affiliate {
  .become-affiliate-container {
    .become-affiliate-content {
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      position: relative;

      &:after {
        background: linear-gradient(100.74deg, #FFFFFF 37.78%, rgba(255, 240, 219, 0.951811) 59.19%, rgba(255, 217, 167, 0.88129) 71.56%, rgba(255, 153, 17, 0.68) 100%);
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 8px;

        @media screen and (max-width: constant.$tablet-size) {
          opacity: 0.6;
        }
      }

      @media screen and (max-width: constant.$small-desktop-size) {
        margin-left: 24px;
      }

      .become-affiliate-information-text {
        position: relative;
        z-index: 2;

        @media screen and (max-width: constant.$small-desktop-size) {
          margin-right: 24px;
        }

        .become-affiliate-heading {
          font-size: 16px;
          line-height: 16px;
          text-transform: uppercase;
          color: constant.$main-blue;
          margin-top: 72px;

          @media screen and (max-width: constant.$small-desktop-size) {
            margin-top: 48px;
          }
        }

        .become-affiliate-text {
          margin-top: 16px;

          @media screen and (max-width: constant.$tablet-size) {
            font-size: 36px;
            line-height: 40px;
          }

          .become-affiliate-logo {
            max-width: 170px;
          }
        }

        .become-affiliate-commission {
          margin-top: 8px;
          @include mixin.description;
          max-width: 696px;

          @media screen and (max-width: constant.$tablet-size) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .become-affiliate-button {
          margin-top: 40px;
          display: inline-block;
          font-family: Montserrat, sans-serif;
          font-weight: 800;
          font-size: 16px;
          line-height: 16px;
          color: white;
          text-transform: uppercase;
          padding: 12px 16px;
          background: constant.$main-blue;
          box-shadow: 0 1px 3px rgba(0, 119, 221, .4), 0 3px 6px rgba(0, 119, 221, .2);
          border-radius: 24px;
          transition: box-shadow ease 200ms;

          &:hover {
            box-shadow: 0 1px 3px rgba(0, 119, 221, .6), 0 3px 6px rgba(0, 119, 221, .6);
          }
        }

        .become-affiliate-description {
          margin-top: 16px;
          @include mixin.description;
          font-size: 16px;
          line-height: 24px;
          max-width: 696px;
          opacity: 0.6;

          @media screen and (max-width: constant.$small-desktop-size) {
            margin-bottom: 24px;
          }
        }
      }
    }

    .become-affiliate-image-block {
      position: relative;
      z-index: 2;

      .become-affiliate-image {
        max-width: 659px;
        height: 100%;
        border-radius: 0 8px 8px 0;

        @media screen and (max-width: constant.$small-desktop-size) {
          display: none;
        }
      }
    }
  }
}
