@use "/src/styles/mixins" as mixin;
@use "/src/styles/constants" as constant;

.customer-experience {
  margin-bottom: 200px;

  @media screen and (max-width: constant.$tablet-size) {
    margin-bottom: 176px;
  }

  @media screen and (max-width: constant.$mobile-size) {
    margin-bottom: 104px;
  }

  .customer-experience-container {
    .customer-experience-info {
      margin-bottom: 32px;

      .customer-experience-text {
        margin-bottom: 12px;

        @media screen and (max-width: 1400px) {
          text-align: center;
        }
      }

      .customer-experience-information-text {
        @include mixin.description;
        max-width: 696px;

        @media screen and (max-width: 1400px) {
          margin: 0 auto;
          text-align: center;
        }

        @media screen and (max-width: constant.$tablet-size) {
          font-size: 18px;
          line-height: 24px;
        }

        @media screen and (max-width: constant.$mobile-size) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .customer-experience-slider {

      &.swiper {
        overflow: unset;
      }

      .swiper-wrapper {
        .swiper-slide {
          width: auto;
        }

        .customer-review {
          .customer-experience-card {
            width: 456px;
            height: 288px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            position: relative;
            padding: 24px;

            @media screen and (max-width: constant.$tablet-size) {
              width: 404px;
              height: 243px;
            }

            @media screen and (max-width: constant.$mobile-size) {
              width: 280px;
              height: 340px;
            }

            &.long-card {
              width: 936px;

              @media screen and (max-width: constant.$tablet-size) {
                width: 705px;
              }

              @media screen and (max-width: constant.$mobile-size) {
                width: 420px;
              }

              .customer-experience-card-content {
                .customer-experience-description {
                  max-width: 888px;
                }
              }
            }

            .customer-experience-card-content {
              .quotes {
                max-width: 34px;
              }

              .customer-experience-card-heading {
                margin-top: 16px;
                font-size: 24px;
                line-height: 32px;

                @media screen and (max-width: constant.$tablet-size) {
                  font-size: 20px;
                  line-height: 24px;
                }

                @media screen and (max-width: constant.$mobile-size) {
                  font-size: 18px;
                  line-height: 24px;
                }
              }

              .customer-experience-description {
                margin-top: 8px;
                @include mixin.description;
                font-size: 16px;
                line-height: 24px;
              }
            }

            .card-triangle {
              position: absolute;
              bottom: -38px;
              left: 7px;
              width: 48px;
            }
          }

          .customer {
            margin-top: 24px;
            display: flex;

            .customer-image {
              background-color: white;
              width: 56px;
              height: 56px;
              border-radius: 50%;
              filter: drop-shadow(0px 2px 4px rgba(black, 0.1)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
              display: flex;
              justify-content: center;
              align-items: center;

              &.google-customer {
                padding-left: 5px;

                svg {
                  width: 25px;
                }
              }

              &.instagram-customer {
                background: radial-gradient(179.06% 43.44% at -16.75% 7.2%, #3771C8 0%, #3771C8 12.8%, rgba(102, 0, 255, 0) 100%),
                radial-gradient(92.18% 99.11% at 26.56% 107.7%, #FFDD55 0%, #FFDD55 10%, #FF543E 50%, #C837AB 100%);

                svg {
                  width: 24px;
                }
              }

              &.twitter-customer {
                color: white;
                background-color: #41ABE1;

                svg {
                  width: 31px;
                }
              }

              &.no-avatar {
                background-color: constant.$main-orange;

                svg {
                  width: 28px;
                }
              }
            }

            .customer-info {
              margin-left: 24px;

              .customer-name {
                font-size: 24px;
                line-height: 32px;

                @media screen and (max-width: constant.$tablet-size) {
                  font-size: 20px;
                  line-height: 24px;
                }

                @media screen and (max-width: constant.$mobile-size) {
                  font-size: 18px;
                  line-height: 24px;
                }
              }

              .date-review {
                @include mixin.description;
                font-size: 16px;
                line-height: 24px;

                @media screen and (max-width: constant.$tablet-size) {
                  font-size: 14px;
                  line-height: 20px;
                }

                @media screen and (max-width: constant.$mobile-size) {
                  font-size: 12px;
                  line-height: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}


