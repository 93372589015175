@use '/src/styles/constants' as constant;
@use '/src/styles/mixins' as mixin;

.navigation-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.1);
  color: constant.$main-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 223px;
  background-color: white;

  @media screen and (max-width: constant.$tablet-size) {
    top: 163px;
  }

  @media screen and (max-width: 725px) {
    display: none;
  }

  svg {
    padding-left: 3px;
    width: 11px;
  }

  &.swiper-button-disabled {
    opacity: 0.7;
  }
}

.prompt-smart-can-help {
  margin: 200px 0 168px;

  @media screen and (max-width: constant.$tablet-size) {
    margin: 176px 0 144px;
  }

  @media screen and (max-width: constant.$mobile-size) {
    margin: 104px 0 80px;
  }

  .prompt-smart-can-help-container {
    max-width: 1632px;
    padding: 0 96px;

    @media screen and (max-width: 1400px) {
      padding: 0 16px;
      max-width: 1000px;
    }

    .prompt-smart-can-help-content {
      position: relative;

      .slider-container {
        .swiper-wrapper {
          .slider-card {
            display: flex;
            width: 100% !important;

            @media screen and (max-width: 1400px) {
              flex-direction: column;
              align-items: center;
            }

            img {
              max-width: 696px;
              height: 489px;
              width: 100%;
              border-radius: 16px;
              @media screen and (max-width: 1400px) {
                height: unset;
                max-width: 640px;
              }

              @media screen and (max-width: constant.$tablet-size) {
                max-width: 530px;
                height: 366px;
              }
              @media screen and (max-width: 562px) {
                max-width: 424px;
                height: 322px;
              }

              @media screen and (max-width: 456px) {
                max-width: 342px;
                height: 270px;
              }

              @media screen and (max-width: 355px) {
                max-width: 295px;
                height: 242px;
              }
            }

            .slide-description {
              margin-left: 24px;
              padding-top: 72px;
              max-width: 696px;
              width: 100%;
              display: flex;
              flex-direction: column;

              @media screen and (max-width: 1400px) {
                align-items: center;
                text-align: center;
                margin-left: unset;
                padding-top: 24px;
              }

              @media screen and (max-width: constant.$tablet-size) {
                max-width: 550px;
              }

              .can-help-text {
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
                font-family: Montserrat, sans-serif;
                font-weight: 800;
                color: constant.$main-blue;
                margin-bottom: 40px;

                @media screen and (max-width: constant.$tablet-size) {
                  margin-bottom: 16px;
                }

                @media screen and (max-width: 456px) {
                  font-size: 16px;
                  line-height: 24px;
                }
              }

              .slide-heading {
                margin-bottom: 16px;

                @media screen and (max-width: 456px) {
                  font-size: 24px;
                  line-height: 32px;
                }
              }

              .slide-description-text {
                @include mixin.description;
                max-width: 696px;
                margin-bottom: 24px;

                @media screen and (max-width: constant.$tablet-size) {
                  margin-bottom: 32px;
                  font-size: 18px;
                  line-height: 24px;
                }

                @media screen and (max-width: 456px) {
                  font-size: 16px;
                  line-height: 24px;
                }

                .highlight-description-text {
                  color: constant.$main-red;
                }
              }

              .get-started {
                margin-top: auto;
                font-family: Montserrat, sans-serif;
                font-weight: 800;
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
                border-radius: 24px;
                font-style: normal;
                display: inline-block;
                text-align: center;
                color: constant.$main-dark;
                background: constant.$orange-gradient;
                box-shadow: 0 1px 3px rgba(255, 153, 17, 0.4), 0 3px 6px rgba(255, 153, 17, 0.2);
                padding: 12px 16px;
                max-width: 197px;
                margin-bottom: 72px;
                transition: box-shadow ease 200ms;

                &:hover {
                  box-shadow: 0 4px 16px rgba(255, 153, 17, 0.6);
                }

                @media screen and (max-width: constant.$tablet-size) {
                  margin-bottom: unset;
                }
              }
            }

            .slider-pagination {
              position: static;
              display: flex;
              align-items: center;
              height: 8px;
              margin-bottom: 36px;

              @media screen and (max-width: 1400px) {
                justify-content: center;
              }

              @media screen and (max-width: constant.$tablet-size) {
                margin-bottom: 16px;
              }

              .swiper-pagination-bullet {
                height: 6px;
                width: 6px;
                background-color: constant.$main-dark;
                opacity: 0.6;

                &.swiper-pagination-bullet-active {
                  background-color: constant.$main-orange;
                  opacity: unset;
                  height: 8px;
                  width: 8px;
                }
              }
            }
          }
        }
      }
    }

    .next-slide-btn {
      right: -80px;

      @media screen and (max-width: 1400px) {
        right: 0;
        z-index: 2;
      }

      @media screen and (max-width: constant.$tablet-size) {
        right: 20px;
        z-index: 2;
      }
    }

    .prev-slide-btn {
      left: -80px;

      @media screen and (max-width: 1400px) {
        left: 0;
        z-index: 2;
      }

      @media screen and (max-width: constant.$tablet-size) {
        left: 20px;
        z-index: 2;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }
}


