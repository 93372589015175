@font-face {
  font-family: 'Montserrat';
  src: url(/src/fonts/Montserrat-ExtraBold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/src/fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/src/fonts/Montserrat-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url(/src/fonts/OpenSans-ExtraBold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url(/src/fonts/OpenSans-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url(/src/fonts/OpenSans-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
