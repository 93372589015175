@import "./fonts";
@import "./constants";
@import "./mixins";

body {
  margin: 0;
  padding: 0;
}

header {
  z-index: 10;
  top: 0;
  position: fixed;
  width: 100%;
}
.scrolled-header {
  background-color: white;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

div, p, a, img, form, button {
  box-sizing: border-box;
  margin: 0;
}

a {
  text-decoration: none;
}

ul, li {
  display: block;
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1472px;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;

  @media screen and (max-width: $tablet-size) {
    max-width: 736px;
    padding: 0 16px;
  }
}

.heading {
  font-size: 40px;
  line-height: 48px;
  text-transform: capitalize;
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  color: $main-dark;

  @media screen and (max-width: $tablet-size) {
    font-size: 36px;
    line-height: 40px;
  }

  @media screen and (max-width: $mobile-size) {
    font-size: 24px;
    line-height: 32px;
  }
}

.wrapper {
  overflow: hidden;
}

.download-links {
  display: flex;
  gap: 24px;

  a {
    display: inline-flex;

    &.app-store-link {
      width: 120px;
      @media (min-width: 801px) {
        width: 160px;
      }
    }

    &.google-play-link {
      width: 135px;
      @media (min-width: 801px) {
        width: 183px;
      }
    }

    img {
      width: 100%;
    }
  }
}