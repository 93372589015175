@use "/src/styles/constants" as constant;
@use "/src/styles/mixins" as mixin;

$flip-columns-size: 708px;

@mixin heading {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 16px;

  @media screen and (max-width: 1180px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media screen and (max-width: constant.$tablet-size) {
    margin-bottom: 8px;
  }

  @media screen and (max-width: $flip-columns-size) {
    text-align: center;
  }

  @media screen and (max-width: constant.$mobile-size) {
    font-size: 20px;
    line-height: 24px;
  }
}

.why-prompt-smart {
  padding-top: 200px;

  @media screen and (max-width: constant.$tablet-size) {
    padding-top: 128px;
  }

  @media screen and (max-width: constant.$mobile-size) {
    padding-top: 104px;
  }

  .why-prompt-smart-container {
    .why-prompt-smart-text {
      font-size: 56px;
      line-height: 56px;
      text-align: center;
      margin-bottom: 72px;

      @media screen and (max-width: constant.$tablet-size) {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 40px;
      }

      @media screen and (max-width: constant.$mobile-size) {
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 32px;
      }
    }

    .functionality-review {
      @media screen and (max-width: $flip-columns-size) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .voice-track-technology {
        display: flex;
        align-items: center;
        padding-bottom: 80px;

        @media screen and (max-width: $flip-columns-size) {
          flex-direction: column;
          max-width: 404px;
          width: 100%;
        }

        @media screen and (max-width: constant.$tablet-size) {
          padding-bottom: 48px;
        }

        .voice-track-image {
          @include mixin.image-block;
          position: relative;

          .voice-track-image-first {
            max-width: 223px;
            position: absolute;
            bottom: 0;
            left: 85px;

            @media screen and (max-width: 1000px) {
              left: 6px;
            }

            @media screen and (max-width: 806px) {
              max-width: 200px;
            }

            @media screen and (max-width: constant.$tablet-size) {
              max-width: 183px;
            }

            @media screen and (max-width: constant.$mobile-size) {
              max-width: 146px;
            }
          }

          .voice-track-image-second {
            max-width: 235px;
            position: absolute;
            bottom: 0;
            right: 35px;

            @media screen and (max-width: 1000px) {
              right: -49px;
            }

            @media screen and (max-width: constant.$tablet-size) {
              right: -40px;
              max-width: 200px;
            }

            @media screen and (max-width: constant.$mobile-size) {
              max-width: 170px;
              right: -30px;
            }
          }
        }

        .voice-track-description {
          margin-left: 40px;

          @media screen and (max-width: constant.$tablet-size) {
            margin-left: 24px;
          }

          @media screen and (max-width: $flip-columns-size) {
            margin: 16px 0 0;
          }

          .voice-track-heading {
            @include heading;
          }

          .voice-track-description-text {
            @include mixin.description;

            @media screen and (max-width: 1180px) {
              font-size: 18px;
            }

            @media screen and (max-width: 785px) {
              font-size: 16px;
              line-height: 24px;
            }

            @media screen and (max-width: $flip-columns-size) {
              text-align: center;
            }
          }
        }
      }

      .enhance-workflow {
        display: flex;
        align-items: center;
        margin-bottom: 200px;

        @media screen and (max-width: constant.$tablet-size) {
          margin-bottom: 143px;
        }

        @media screen and (max-width: $flip-columns-size) {
          flex-direction: column;
          max-width: 404px;
          width: 100%;
          margin-bottom: 80px;
        }

        .workflow-description {
          margin-right: 40px;
          text-align: right;

          @media screen and (max-width: 1180px) {
            text-align: left;
          }

          @media screen and (max-width: constant.$tablet-size) {
            margin-right: 16px;
          }

          @media screen and (max-width: $flip-columns-size) {
            margin: 16px 0 0;
            order: 2;
          }

          .workflow-heading {
            @include heading;
          }

          .workflow-description-text {
            @include mixin.description;
            margin-left: auto;

            @media screen and (max-width: 1180px) {
              font-size: 18px;
            }

            @media screen and (max-width: 785px) {
              font-size: 16px;
              line-height: 24px;
            }

            @media screen and (max-width: $flip-columns-size) {
              text-align: center;
            }
          }
        }

        .workflow-image {
          @include mixin.image-block;
          position: relative;

          @media screen and (max-width: constant.$mobile-size) {
            overflow: hidden;
          }

          img {
            position: absolute;
            bottom: 0;
            width: inherit;

            @media screen and (max-width: constant.$mobile-size) {
              width: 420px;
            }
          }
        }
      }

      .advantages {
        .speech-recognition {
          text-align: center;
          font-size: 32px;
          line-height: 40px;
          margin: 0 auto 30px;

          @media screen and (max-width: 1072px) {
            max-width: 694px;
          }

          @media screen and (max-width: constant.$tablet-size) {
            font-size: 24px;
            line-height: 32px;
            max-width: 617px;
            margin: 0 auto 30px;
            text-transform: unset;
          }
        }

        .advantages-cards {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 22px;

          @media screen and (max-width: 1450px) {
            justify-content: center;
          }

          @media screen and (max-width: 725px) {
            gap: 32px;
          }

          div {
            max-width: 336px;
            width: 100%;

            @media screen and (max-width: 725px) {
              max-width: 400px;
            }
          }
        }

        .advantages-card {
          height: 88px;
          background: rgba(constant.$main-blue, 0.1);
          border-radius: 4px;
          margin-bottom: 16px;
          color: constant.$main-blue;
          display: flex;

          .advantages-card-icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .advantage-card__icon {
              width: 40px;
              height: 40px;

              @media screen and (max-width: constant.$mobile-size) {
                width: 24px;
                height: 24px;
              }
            }
          }

          @media screen and (max-width: constant.$mobile-size) {
            height: 56px;
          }
        }

        .card-description {
          .card-heading {
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            margin-bottom: 8px;

            @media screen and (max-width: constant.$tablet-size) {
              font-size: 20px;
              line-height: 24px;
            }

            @media screen and (max-width: constant.$mobile-size) {
              font-size: 18px;
            }
          }

          .description-text {
            color: constant.$main-dark;
            font-family: OpenSans, sans-serif;
            text-align: center;
            font-size: 16px;
            line-height: 24px;

            @media screen and (max-width: constant.$tablet-size) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
