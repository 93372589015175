@use "/src/styles/constants" as constant;

.header-content {
  position: relative;
  margin-top: 84px;

  @media screen and (max-width: constant.$tablet-size) {
    margin-top: 64px;
  }

  @media screen and (max-width: constant.$mobile-size) {
    margin-top: 56px;
  }

  @media screen and (max-width: 645px) {
    &:after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, #FFFFFF 13.58%, rgba(255, 236, 211, 0.940945) 34.74%, rgba(255, 231, 198, 0.923813) 50.94%, rgba(255, 209, 147, 0.855186) 71.65%, rgba(255, 189, 102, 0.793962) 87.55%, rgba(255, 153, 17, 0.68) 99.98%);
      opacity: 0.8;
    }
  }

  .header-background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 645px) {
      display: none;
    }
  }

  .content-container {
    display: flex;

    .content-information-text {
      font-family: Montserrat, sans-serif;
      max-width: 696px;
      width: 100%;
      margin: 208px 0 204px;
      z-index: 2;

      @media screen and (max-width: 1590px) {
        margin: 144px 0 146px;
      }

      @media screen and (max-width: 955px) {
        margin: 110px 0 106px;
      }

      @media screen and (max-width: constant.$tablet-size) {
        margin: 66px 0 88px;
      }

      @media screen and (max-width: 645px) {
        margin: 92px 0 112px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .header-content-logo {
        max-width: 424px;
        width: 100%;
        margin-bottom: 40px;

        @media screen and (max-width: 1300px) {
          max-width: 355px;
        }

        @media screen and (max-width: 955px) {
          max-width: 290px;
          margin-bottom: 25px;
        }

        @media screen and (max-width: constant.$tablet-size) {
          max-width: 188px;
          margin-bottom: 12px;
        }
      }

      .information-text {
        font-size: 56px;
        line-height: 56px;
        font-weight: 800;
        color: constant.$main-dark;

        @media screen and (max-width: 1590px) {
          font-size: 48px;
          line-height: 48px;
        }

        @media screen and (max-width: 1300px) {
          font-size: 42px;
          line-height: 42px;
        }

        @media screen and (max-width: 800px) {
          font-size: 36px;
          line-height: 40px;
        }

        @media screen and (max-width: 645px) {
          text-align: center;
        }

        span {
          color: constant.$main-blue;
        }
      }

      .download-links {
        margin-top: 16px;
        @media (min-width: 901px) {
          margin-top: 40px;
        }
      }
    }
  }

  .multi-devices {
    position: relative;
    max-width: 927px;
    width: 100%;
    z-index: 2;

    @media screen and (max-width: constant.$tablet-size) {
      max-width: 300px;
    }

    @media screen and (max-width: 645px) {
      max-width: unset;
      width: unset;
      z-index: unset;
      position: unset;
    }

    .multi-devices-image {
      position: absolute;
      bottom: 43px;
      right: -240px;

      @media screen and (max-width: 1700px) {
        bottom: 70px;
      }

      @media screen and (max-width: 1590px) {
        max-width: 750px;
        right: -95px;
        bottom: 40px;
      }

      @media screen and (max-width: 1300px) {
        max-width: 750px;
        right: -130px;
      }

      @media screen and (max-width: 1086px) {
        max-width: 670px;
        right: -120px;
      }

      @media screen and (max-width: constant.$tablet-size) {
        max-width: 487px;
        right: -98px;
        bottom: 25px;
      }

      @media screen and (max-width: 645px) {
        display: none;
      }
    }
  }
}
