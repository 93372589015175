@use "/src/styles/constants" as constant;
@use "/src/styles/mixins" as mixin;

@mixin burger-stick {
  content: '';
  background-color: black;
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  border-radius: 8px;
  transition: all 0.3s ease 0s;
}

body {
  &.lock {
    overflow: hidden;
  }
}

.header {
  @media screen and (max-width: $burger-screen-size) {
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: white;
      z-index: 10;
    }
  }
}

.header-container {

  .header-navigation {
    padding: 24px 0;
    display: flex;
    align-items: center;

    @media screen and (max-width: constant.$tablet-size) {
      padding: 12px 0;
    }

    @media screen and (max-width: constant.$mobile-size) {
      padding: 16px 0;
    }

    .logo {
      margin-right: auto;
      z-index: 15;

      img {
        height: 34px;

        @media screen and (max-width: constant.$tablet-size) {
          max-width: 144px;
          height: 24px;
        }
      }
    }

    & * {
      display: flex;
    }

    .header-burger {
      display: none;

      @media screen and (max-width: $burger-screen-size) {
        position: relative;
        display: block;
        width: 24px;
        height: 15px;
        z-index: 15;
      }

      span {
        @media screen and (max-width: $burger-screen-size) {
          top: 6px;
          @include burger-stick;
        }
      }

      &:before {
        @media screen and (max-width: $burger-screen-size) {
          top: 0;
          @include burger-stick;
        }
      }

      &:after {
        @media screen and (max-width: $burger-screen-size) {
          bottom: 0;
          @include burger-stick;
        }
      }

      &.header-burger__active {
        span {
          transform: scale(0);
        }

        &:before {
          transform: rotate(45deg);
          top: 6px;
        }

        &:after {
          transform: rotate(-45deg);
          bottom: 6px;
        }
      }
    }

    .header-navigation__menu {
      @media screen and (max-width: $burger-screen-size) {
        position: fixed;
        top: -300%;
        transition: all 0.3s ease 0s;
        padding: 104px 16px 40px;
        z-index: 5;
        height: 100%;
        width: 100%;
        background-color: white;
        left: 0;
      }
      @media screen and (max-width: constant.$tablet-size) {
        padding: 104px 0 40px;
      }

      &.header-burger__active {
        top: 0;
        height: 100%;
      }

      .header-navigation__content {
        align-items: center;
        @media screen and (max-width: $burger-screen-size) {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          align-items: inherit;
        }
        @media screen and (max-width: constant.$tablet-size) {
          max-width: 736px;
          padding: 0 16px;
          margin: 0 auto;
        }

        .header-menu {
          font-family: Montserrat, sans-serif;
          font-weight: 700;
          margin-right: 80px;

          @media screen and (max-width: 855px) {
            margin-right: 24px;
          }

          @media screen and (max-width: $burger-screen-size) {
            flex-direction: column;
            border-bottom: 1px solid rgba(constant.$main-dark, .4);
            margin-right: unset;
          }

          .product-affiliate-program {
            display: none;

            @media screen and (max-width: $burger-screen-size) {
              display: block;
            }
          }

          a {
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            color: constant.$main-dark;
            transition: color ease 200ms;

            &:hover {
              color: constant.$main-orange;
            }

            &:not(:last-child) {
              margin-right: 64px;
              @media screen and (max-width: 1030px) {
                margin-right: 40px;
              }

              &.help {
                margin-right: 0;
              }
            }

            @media screen and (max-width: $burger-screen-size) {
              font-size: 16px;
              line-height: 16px;
              margin: 0 0 32px;
              font-weight: 800;
            }
          }
        }

        .header-privacy-policy {
          display: none;

          @media screen and (max-width: $burger-screen-size) {
            display: block;
          }

          a, span {
            font-family: 'OpenSans', sans-serif;
            font-size: 16px;
            line-height: 16px;
            margin-top: 32px;
            font-weight: 400;
            color: constant.$main-dark;

            @media screen and (max-height: 965px) {
              margin-top: 24px;
            }

            @media screen and (max-height: 655px) {
              margin-top: 16px;
            }
          }

          .privacy-policy {
            font-family: 'Montserrat', sans-serif;
            font-weight: 800;
            text-transform: uppercase;
          }

          .privacy-policy-types {
            display: block;
          }
        }

        .auth-buttons {
          font-family: Montserrat, sans-serif;
          font-weight: 800;

          @media screen and (max-width: $burger-screen-size) {
            margin-top: auto;
            padding-top: 32px;
            border-top: 1px solid rgba(constant.$main-dark, .4);
            flex-wrap: wrap;
          }

          @media screen and (max-width: 440px) {
            justify-content: space-between;
          }

          a {
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            border-radius: 24px;
            font-style: normal;
            display: inline-block;
            text-align: center;
          }

          .login {
            color: constant.$main-dark;
            border: 1px solid rgba(constant.$main-dark, 0.4);
            margin-right: 24px;
            display: flex;
            align-items: center;
            padding: 0 16px;
            transition: all ease 200ms;

            &:hover {
              background: rgba(constant.$main-orange, .12);
              color: constant.$main-orange;
              border: 1px solid rgba(constant.$main-dark, .2);
            }

            @media screen and (max-width: 855px) {
              margin-right: 16px;
            }

            @media screen and (max-width: $burger-screen-size) {
              order: 2;
              padding: 13px 43px;
              margin-right: unset;
            }

            @media screen and (max-width: 364px) {
              margin-top: 16px;
            }
          }

          .get-started {
            color: constant.$main-dark;
            background: constant.$orange-gradient;
            box-shadow: 0 1px 3px rgba(255, 153, 17, 0.4), 0 3px 6px rgba(255, 153, 17, 0.2);
            padding: 10px 16px;
            transition: box-shadow ease 200ms;

            &:hover {
              box-shadow: 0 4px 16px rgba(255, 153, 17, 0.6);
            }

            @media screen and (max-width: constant.$tablet-size) {
              padding: 12px 16px;
            }

            @media screen and (max-width: $burger-screen-size) {
              padding: 14px 22px;
              margin-right: 16px;
            }

            @media screen and (max-width: 440px) {
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}
