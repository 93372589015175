// Gradient colors
$dark-gradient: linear-gradient(180deg, #37474F 0%, #263238 100%);
$blue-gradient: radial-gradient(50% 50% at 50% 50%, #0288D1 0%, #0277BD 100%);
$orange-gradient: linear-gradient(180deg, #FFB74D 0%, #FB8C00 100%);

//Main colors
$main-orange: #FF9911;
$main-dark: #263238;
$main-blue: #0077DD;
$main-purple: #86608E;
$main-red: #EB5757;
$main-soft-light: #F4F4F5;
$main-soft-dark: #EAEBEC;
$border-color: #C4C4C4;

//Container size
$tablet-size: 900px;
$mobile-size: 545px;
$burger-screen-size: 980px;
$small-desktop-size: 1200px;
