@font-face {
  font-family: "Montserrat";
  src: url(/src/fonts/Montserrat-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url(/src/fonts/Montserrat-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url(/src/fonts/Montserrat-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url(/src/fonts/OpenSans-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url(/src/fonts/OpenSans-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans";
  src: url(/src/fonts/OpenSans-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
}

header {
  z-index: 10;
  top: 0;
  position: fixed;
  width: 100%;
}

.scrolled-header {
  background-color: white;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

div, p, a, img, form, button {
  box-sizing: border-box;
  margin: 0;
}

a {
  text-decoration: none;
}

ul, li {
  display: block;
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1472px;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;
}
@media screen and (max-width: 900px) {
  .container {
    max-width: 736px;
    padding: 0 16px;
  }
}

.heading {
  font-size: 40px;
  line-height: 48px;
  text-transform: capitalize;
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  color: #263238;
}
@media screen and (max-width: 900px) {
  .heading {
    font-size: 36px;
    line-height: 40px;
  }
}
@media screen and (max-width: 545px) {
  .heading {
    font-size: 24px;
    line-height: 32px;
  }
}

.wrapper {
  overflow: hidden;
}

.download-links {
  display: flex;
  gap: 24px;
}
.download-links a {
  display: inline-flex;
}
.download-links a.app-store-link {
  width: 120px;
}
@media (min-width: 801px) {
  .download-links a.app-store-link {
    width: 160px;
  }
}
.download-links a.google-play-link {
  width: 135px;
}
@media (min-width: 801px) {
  .download-links a.google-play-link {
    width: 183px;
  }
}
.download-links a img {
  width: 100%;
}

body.lock {
  overflow: hidden;
}

@media screen and (max-width: 980px) {
  .header:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    z-index: 10;
  }
}

.header-container .header-navigation {
  padding: 24px 0;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .header-container .header-navigation {
    padding: 12px 0;
  }
}
@media screen and (max-width: 545px) {
  .header-container .header-navigation {
    padding: 16px 0;
  }
}
.header-container .header-navigation .logo {
  margin-right: auto;
  z-index: 15;
}
.header-container .header-navigation .logo img {
  height: 34px;
}
@media screen and (max-width: 900px) {
  .header-container .header-navigation .logo img {
    max-width: 144px;
    height: 24px;
  }
}
.header-container .header-navigation * {
  display: flex;
}
.header-container .header-navigation .header-burger {
  display: none;
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-burger {
    position: relative;
    display: block;
    width: 24px;
    height: 15px;
    z-index: 15;
  }
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-burger span {
    top: 6px;
    content: "";
    background-color: black;
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    border-radius: 8px;
    transition: all 0.3s ease 0s;
  }
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-burger:before {
    top: 0;
    content: "";
    background-color: black;
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    border-radius: 8px;
    transition: all 0.3s ease 0s;
  }
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-burger:after {
    bottom: 0;
    content: "";
    background-color: black;
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    border-radius: 8px;
    transition: all 0.3s ease 0s;
  }
}
.header-container .header-navigation .header-burger.header-burger__active span {
  transform: scale(0);
}
.header-container .header-navigation .header-burger.header-burger__active:before {
  transform: rotate(45deg);
  top: 6px;
}
.header-container .header-navigation .header-burger.header-burger__active:after {
  transform: rotate(-45deg);
  bottom: 6px;
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-navigation__menu {
    position: fixed;
    top: -300%;
    transition: all 0.3s ease 0s;
    padding: 104px 16px 40px;
    z-index: 5;
    height: 100%;
    width: 100%;
    background-color: white;
    left: 0;
  }
}
@media screen and (max-width: 900px) {
  .header-container .header-navigation .header-navigation__menu {
    padding: 104px 0 40px;
  }
}
.header-container .header-navigation .header-navigation__menu.header-burger__active {
  top: 0;
  height: 100%;
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content {
  align-items: center;
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: inherit;
  }
}
@media screen and (max-width: 900px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content {
    max-width: 736px;
    padding: 0 16px;
    margin: 0 auto;
  }
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .header-menu {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  margin-right: 80px;
}
@media screen and (max-width: 855px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .header-menu {
    margin-right: 24px;
  }
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .header-menu {
    flex-direction: column;
    border-bottom: 1px solid rgba(38, 50, 56, 0.4);
    margin-right: unset;
  }
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .header-menu .product-affiliate-program {
  display: none;
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .header-menu .product-affiliate-program {
    display: block;
  }
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .header-menu a {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #263238;
  transition: color ease 200ms;
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .header-menu a:hover {
  color: #FF9911;
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .header-menu a:not(:last-child) {
  margin-right: 64px;
}
@media screen and (max-width: 1030px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .header-menu a:not(:last-child) {
    margin-right: 40px;
  }
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .header-menu a:not(:last-child).help {
  margin-right: 0;
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .header-menu a {
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 32px;
    font-weight: 800;
  }
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .header-privacy-policy {
  display: none;
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .header-privacy-policy {
    display: block;
  }
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .header-privacy-policy a, .header-container .header-navigation .header-navigation__menu .header-navigation__content .header-privacy-policy span {
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  line-height: 16px;
  margin-top: 32px;
  font-weight: 400;
  color: #263238;
}
@media screen and (max-height: 965px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .header-privacy-policy a, .header-container .header-navigation .header-navigation__menu .header-navigation__content .header-privacy-policy span {
    margin-top: 24px;
  }
}
@media screen and (max-height: 655px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .header-privacy-policy a, .header-container .header-navigation .header-navigation__menu .header-navigation__content .header-privacy-policy span {
    margin-top: 16px;
  }
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .header-privacy-policy .privacy-policy {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .header-privacy-policy .privacy-policy-types {
  display: block;
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons {
  font-family: Montserrat, sans-serif;
  font-weight: 800;
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons {
    margin-top: auto;
    padding-top: 32px;
    border-top: 1px solid rgba(38, 50, 56, 0.4);
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 440px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons {
    justify-content: space-between;
  }
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons a {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 24px;
  font-style: normal;
  display: inline-block;
  text-align: center;
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons .login {
  color: #263238;
  border: 1px solid rgba(38, 50, 56, 0.4);
  margin-right: 24px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  transition: all ease 200ms;
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons .login:hover {
  background: rgba(255, 153, 17, 0.12);
  color: #FF9911;
  border: 1px solid rgba(38, 50, 56, 0.2);
}
@media screen and (max-width: 855px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons .login {
    margin-right: 16px;
  }
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons .login {
    order: 2;
    padding: 13px 43px;
    margin-right: unset;
  }
}
@media screen and (max-width: 364px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons .login {
    margin-top: 16px;
  }
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons .get-started {
  color: #263238;
  background: linear-gradient(180deg, #FFB74D 0%, #FB8C00 100%);
  box-shadow: 0 1px 3px rgba(255, 153, 17, 0.4), 0 3px 6px rgba(255, 153, 17, 0.2);
  padding: 10px 16px;
  transition: box-shadow ease 200ms;
}
.header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons .get-started:hover {
  box-shadow: 0 4px 16px rgba(255, 153, 17, 0.6);
}
@media screen and (max-width: 900px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons .get-started {
    padding: 12px 16px;
  }
}
@media screen and (max-width: 980px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons .get-started {
    padding: 14px 22px;
    margin-right: 16px;
  }
}
@media screen and (max-width: 440px) {
  .header-container .header-navigation .header-navigation__menu .header-navigation__content .auth-buttons .get-started {
    margin-right: 6px;
  }
}

.why-prompt-smart {
  padding-top: 200px;
}
@media screen and (max-width: 900px) {
  .why-prompt-smart {
    padding-top: 128px;
  }
}
@media screen and (max-width: 545px) {
  .why-prompt-smart {
    padding-top: 104px;
  }
}
.why-prompt-smart .why-prompt-smart-container .why-prompt-smart-text {
  font-size: 56px;
  line-height: 56px;
  text-align: center;
  margin-bottom: 72px;
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .why-prompt-smart-text {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 545px) {
  .why-prompt-smart .why-prompt-smart-container .why-prompt-smart-text {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 708px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology {
  display: flex;
  align-items: center;
  padding-bottom: 80px;
}
@media screen and (max-width: 708px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology {
    flex-direction: column;
    max-width: 404px;
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology {
    padding-bottom: 48px;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-image {
  min-width: 404px;
  max-width: 696px;
  width: 100%;
  border-radius: 16px;
  background-color: #F4F4F5;
  height: 256px;
  position: relative;
}
@media screen and (max-width: 806px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-image {
    height: 224px;
  }
}
@media screen and (max-width: 545px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-image {
    max-width: 404px;
    min-width: unset;
    height: 176px;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-image .voice-track-image-first {
  max-width: 223px;
  position: absolute;
  bottom: 0;
  left: 85px;
}
@media screen and (max-width: 1000px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-image .voice-track-image-first {
    left: 6px;
  }
}
@media screen and (max-width: 806px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-image .voice-track-image-first {
    max-width: 200px;
  }
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-image .voice-track-image-first {
    max-width: 183px;
  }
}
@media screen and (max-width: 545px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-image .voice-track-image-first {
    max-width: 146px;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-image .voice-track-image-second {
  max-width: 235px;
  position: absolute;
  bottom: 0;
  right: 35px;
}
@media screen and (max-width: 1000px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-image .voice-track-image-second {
    right: -49px;
  }
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-image .voice-track-image-second {
    right: -40px;
    max-width: 200px;
  }
}
@media screen and (max-width: 545px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-image .voice-track-image-second {
    max-width: 170px;
    right: -30px;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description {
  margin-left: 40px;
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description {
    margin-left: 24px;
  }
}
@media screen and (max-width: 708px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description {
    margin: 16px 0 0;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description .voice-track-heading {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 16px;
}
@media screen and (max-width: 1180px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description .voice-track-heading {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description .voice-track-heading {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 708px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description .voice-track-heading {
    text-align: center;
  }
}
@media screen and (max-width: 545px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description .voice-track-heading {
    font-size: 20px;
    line-height: 24px;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description .voice-track-description-text {
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description .voice-track-description-text {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 1180px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description .voice-track-description-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 785px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description .voice-track-description-text {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 708px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .voice-track-technology .voice-track-description .voice-track-description-text {
    text-align: center;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow {
  display: flex;
  align-items: center;
  margin-bottom: 200px;
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow {
    margin-bottom: 143px;
  }
}
@media screen and (max-width: 708px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow {
    flex-direction: column;
    max-width: 404px;
    width: 100%;
    margin-bottom: 80px;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description {
  margin-right: 40px;
  text-align: right;
}
@media screen and (max-width: 1180px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description {
    text-align: left;
  }
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description {
    margin-right: 16px;
  }
}
@media screen and (max-width: 708px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description {
    margin: 16px 0 0;
    order: 2;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description .workflow-heading {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 16px;
}
@media screen and (max-width: 1180px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description .workflow-heading {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description .workflow-heading {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 708px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description .workflow-heading {
    text-align: center;
  }
}
@media screen and (max-width: 545px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description .workflow-heading {
    font-size: 20px;
    line-height: 24px;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description .workflow-description-text {
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
  margin-left: auto;
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description .workflow-description-text {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 1180px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description .workflow-description-text {
    font-size: 18px;
  }
}
@media screen and (max-width: 785px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description .workflow-description-text {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 708px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-description .workflow-description-text {
    text-align: center;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-image {
  min-width: 404px;
  max-width: 696px;
  width: 100%;
  border-radius: 16px;
  background-color: #F4F4F5;
  height: 256px;
  position: relative;
}
@media screen and (max-width: 806px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-image {
    height: 224px;
  }
}
@media screen and (max-width: 545px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-image {
    max-width: 404px;
    min-width: unset;
    height: 176px;
  }
}
@media screen and (max-width: 545px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-image {
    overflow: hidden;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-image img {
  position: absolute;
  bottom: 0;
  width: inherit;
}
@media screen and (max-width: 545px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .enhance-workflow .workflow-image img {
    width: 420px;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .speech-recognition {
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  margin: 0 auto 30px;
}
@media screen and (max-width: 1072px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .speech-recognition {
    max-width: 694px;
  }
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .speech-recognition {
    font-size: 24px;
    line-height: 32px;
    max-width: 617px;
    margin: 0 auto 30px;
    text-transform: unset;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .advantages-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 22px;
}
@media screen and (max-width: 1450px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .advantages-cards {
    justify-content: center;
  }
}
@media screen and (max-width: 725px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .advantages-cards {
    gap: 32px;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .advantages-cards div {
  max-width: 336px;
  width: 100%;
}
@media screen and (max-width: 725px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .advantages-cards div {
    max-width: 400px;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .advantages-card {
  height: 88px;
  background: rgba(0, 119, 221, 0.1);
  border-radius: 4px;
  margin-bottom: 16px;
  color: #0077DD;
  display: flex;
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .advantages-card .advantages-card-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .advantages-card .advantages-card-icon-wrapper .advantage-card__icon {
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 545px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .advantages-card .advantages-card-icon-wrapper .advantage-card__icon {
    width: 24px;
    height: 24px;
  }
}
@media screen and (max-width: 545px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .advantages-card {
    height: 56px;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .card-description .card-heading {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .card-description .card-heading {
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen and (max-width: 545px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .card-description .card-heading {
    font-size: 18px;
  }
}
.why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .card-description .description-text {
  color: #263238;
  font-family: OpenSans, sans-serif;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (max-width: 900px) {
  .why-prompt-smart .why-prompt-smart-container .functionality-review .advantages .card-description .description-text {
    font-size: 14px;
    line-height: 20px;
  }
}

.header-content {
  position: relative;
  margin-top: 84px;
}
@media screen and (max-width: 900px) {
  .header-content {
    margin-top: 64px;
  }
}
@media screen and (max-width: 545px) {
  .header-content {
    margin-top: 56px;
  }
}
@media screen and (max-width: 645px) {
  .header-content:after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #FFFFFF 13.58%, rgba(255, 236, 211, 0.940945) 34.74%, rgba(255, 231, 198, 0.923813) 50.94%, rgba(255, 209, 147, 0.855186) 71.65%, rgba(255, 189, 102, 0.793962) 87.55%, rgba(255, 153, 17, 0.68) 99.98%);
    opacity: 0.8;
  }
}
.header-content .header-background {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 645px) {
  .header-content .header-background {
    display: none;
  }
}
.header-content .content-container {
  display: flex;
}
.header-content .content-container .content-information-text {
  font-family: Montserrat, sans-serif;
  max-width: 696px;
  width: 100%;
  margin: 208px 0 204px;
  z-index: 2;
}
@media screen and (max-width: 1590px) {
  .header-content .content-container .content-information-text {
    margin: 144px 0 146px;
  }
}
@media screen and (max-width: 955px) {
  .header-content .content-container .content-information-text {
    margin: 110px 0 106px;
  }
}
@media screen and (max-width: 900px) {
  .header-content .content-container .content-information-text {
    margin: 66px 0 88px;
  }
}
@media screen and (max-width: 645px) {
  .header-content .content-container .content-information-text {
    margin: 92px 0 112px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.header-content .content-container .content-information-text .header-content-logo {
  max-width: 424px;
  width: 100%;
  margin-bottom: 40px;
}
@media screen and (max-width: 1300px) {
  .header-content .content-container .content-information-text .header-content-logo {
    max-width: 355px;
  }
}
@media screen and (max-width: 955px) {
  .header-content .content-container .content-information-text .header-content-logo {
    max-width: 290px;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 900px) {
  .header-content .content-container .content-information-text .header-content-logo {
    max-width: 188px;
    margin-bottom: 12px;
  }
}
.header-content .content-container .content-information-text .information-text {
  font-size: 56px;
  line-height: 56px;
  font-weight: 800;
  color: #263238;
}
@media screen and (max-width: 1590px) {
  .header-content .content-container .content-information-text .information-text {
    font-size: 48px;
    line-height: 48px;
  }
}
@media screen and (max-width: 1300px) {
  .header-content .content-container .content-information-text .information-text {
    font-size: 42px;
    line-height: 42px;
  }
}
@media screen and (max-width: 800px) {
  .header-content .content-container .content-information-text .information-text {
    font-size: 36px;
    line-height: 40px;
  }
}
@media screen and (max-width: 645px) {
  .header-content .content-container .content-information-text .information-text {
    text-align: center;
  }
}
.header-content .content-container .content-information-text .information-text span {
  color: #0077DD;
}
.header-content .content-container .content-information-text .download-links {
  margin-top: 16px;
}
@media (min-width: 901px) {
  .header-content .content-container .content-information-text .download-links {
    margin-top: 40px;
  }
}
.header-content .multi-devices {
  position: relative;
  max-width: 927px;
  width: 100%;
  z-index: 2;
}
@media screen and (max-width: 900px) {
  .header-content .multi-devices {
    max-width: 300px;
  }
}
@media screen and (max-width: 645px) {
  .header-content .multi-devices {
    max-width: unset;
    width: unset;
    z-index: unset;
    position: unset;
  }
}
.header-content .multi-devices .multi-devices-image {
  position: absolute;
  bottom: 43px;
  right: -240px;
}
@media screen and (max-width: 1700px) {
  .header-content .multi-devices .multi-devices-image {
    bottom: 70px;
  }
}
@media screen and (max-width: 1590px) {
  .header-content .multi-devices .multi-devices-image {
    max-width: 750px;
    right: -95px;
    bottom: 40px;
  }
}
@media screen and (max-width: 1300px) {
  .header-content .multi-devices .multi-devices-image {
    max-width: 750px;
    right: -130px;
  }
}
@media screen and (max-width: 1086px) {
  .header-content .multi-devices .multi-devices-image {
    max-width: 670px;
    right: -120px;
  }
}
@media screen and (max-width: 900px) {
  .header-content .multi-devices .multi-devices-image {
    max-width: 487px;
    right: -98px;
    bottom: 25px;
  }
}
@media screen and (max-width: 645px) {
  .header-content .multi-devices .multi-devices-image {
    display: none;
  }
}

.navigation-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.1);
  color: #263238;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 223px;
  background-color: white;
}
@media screen and (max-width: 900px) {
  .navigation-btn {
    top: 163px;
  }
}
@media screen and (max-width: 725px) {
  .navigation-btn {
    display: none;
  }
}
.navigation-btn svg {
  padding-left: 3px;
  width: 11px;
}
.navigation-btn.swiper-button-disabled {
  opacity: 0.7;
}

.prompt-smart-can-help {
  margin: 200px 0 168px;
}
@media screen and (max-width: 900px) {
  .prompt-smart-can-help {
    margin: 176px 0 144px;
  }
}
@media screen and (max-width: 545px) {
  .prompt-smart-can-help {
    margin: 104px 0 80px;
  }
}
.prompt-smart-can-help .prompt-smart-can-help-container {
  max-width: 1632px;
  padding: 0 96px;
}
@media screen and (max-width: 1400px) {
  .prompt-smart-can-help .prompt-smart-can-help-container {
    padding: 0 16px;
    max-width: 1000px;
  }
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content {
  position: relative;
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card {
  display: flex;
  width: 100% !important;
}
@media screen and (max-width: 1400px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card {
    flex-direction: column;
    align-items: center;
  }
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card img {
  max-width: 696px;
  height: 489px;
  width: 100%;
  border-radius: 16px;
}
@media screen and (max-width: 1400px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card img {
    height: unset;
    max-width: 640px;
  }
}
@media screen and (max-width: 900px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card img {
    max-width: 530px;
    height: 366px;
  }
}
@media screen and (max-width: 562px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card img {
    max-width: 424px;
    height: 322px;
  }
}
@media screen and (max-width: 456px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card img {
    max-width: 342px;
    height: 270px;
  }
}
@media screen and (max-width: 355px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card img {
    max-width: 295px;
    height: 242px;
  }
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description {
  margin-left: 24px;
  padding-top: 72px;
  max-width: 696px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1400px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description {
    align-items: center;
    text-align: center;
    margin-left: unset;
    padding-top: 24px;
  }
}
@media screen and (max-width: 900px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description {
    max-width: 550px;
  }
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .can-help-text {
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  color: #0077DD;
  margin-bottom: 40px;
}
@media screen and (max-width: 900px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .can-help-text {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 456px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .can-help-text {
    font-size: 16px;
    line-height: 24px;
  }
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .slide-heading {
  margin-bottom: 16px;
}
@media screen and (max-width: 456px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .slide-heading {
    font-size: 24px;
    line-height: 32px;
  }
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .slide-description-text {
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
  max-width: 696px;
  margin-bottom: 24px;
}
@media screen and (max-width: 900px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .slide-description-text {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 900px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .slide-description-text {
    margin-bottom: 32px;
    font-size: 18px;
    line-height: 24px;
  }
}
@media screen and (max-width: 456px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .slide-description-text {
    font-size: 16px;
    line-height: 24px;
  }
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .slide-description-text .highlight-description-text {
  color: #EB5757;
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .get-started {
  margin-top: auto;
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 24px;
  font-style: normal;
  display: inline-block;
  text-align: center;
  color: #263238;
  background: linear-gradient(180deg, #FFB74D 0%, #FB8C00 100%);
  box-shadow: 0 1px 3px rgba(255, 153, 17, 0.4), 0 3px 6px rgba(255, 153, 17, 0.2);
  padding: 12px 16px;
  max-width: 197px;
  margin-bottom: 72px;
  transition: box-shadow ease 200ms;
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .get-started:hover {
  box-shadow: 0 4px 16px rgba(255, 153, 17, 0.6);
}
@media screen and (max-width: 900px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slide-description .get-started {
    margin-bottom: unset;
  }
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slider-pagination {
  position: static;
  display: flex;
  align-items: center;
  height: 8px;
  margin-bottom: 36px;
}
@media screen and (max-width: 1400px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slider-pagination {
    justify-content: center;
  }
}
@media screen and (max-width: 900px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slider-pagination {
    margin-bottom: 16px;
  }
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slider-pagination .swiper-pagination-bullet {
  height: 6px;
  width: 6px;
  background-color: #263238;
  opacity: 0.6;
}
.prompt-smart-can-help .prompt-smart-can-help-container .prompt-smart-can-help-content .slider-container .swiper-wrapper .slider-card .slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #FF9911;
  opacity: unset;
  height: 8px;
  width: 8px;
}
.prompt-smart-can-help .prompt-smart-can-help-container .next-slide-btn {
  right: -80px;
}
@media screen and (max-width: 1400px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .next-slide-btn {
    right: 0;
    z-index: 2;
  }
}
@media screen and (max-width: 900px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .next-slide-btn {
    right: 20px;
    z-index: 2;
  }
}
.prompt-smart-can-help .prompt-smart-can-help-container .prev-slide-btn {
  left: -80px;
}
@media screen and (max-width: 1400px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prev-slide-btn {
    left: 0;
    z-index: 2;
  }
}
@media screen and (max-width: 900px) {
  .prompt-smart-can-help .prompt-smart-can-help-container .prev-slide-btn {
    left: 20px;
    z-index: 2;
  }
}
.prompt-smart-can-help .prompt-smart-can-help-container .prev-slide-btn svg {
  transform: rotate(180deg);
}

.customer-experience {
  margin-bottom: 200px;
}
@media screen and (max-width: 900px) {
  .customer-experience {
    margin-bottom: 176px;
  }
}
@media screen and (max-width: 545px) {
  .customer-experience {
    margin-bottom: 104px;
  }
}
.customer-experience .customer-experience-container .customer-experience-info {
  margin-bottom: 32px;
}
.customer-experience .customer-experience-container .customer-experience-info .customer-experience-text {
  margin-bottom: 12px;
}
@media screen and (max-width: 1400px) {
  .customer-experience .customer-experience-container .customer-experience-info .customer-experience-text {
    text-align: center;
  }
}
.customer-experience .customer-experience-container .customer-experience-info .customer-experience-information-text {
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
  max-width: 696px;
}
@media screen and (max-width: 900px) {
  .customer-experience .customer-experience-container .customer-experience-info .customer-experience-information-text {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 1400px) {
  .customer-experience .customer-experience-container .customer-experience-info .customer-experience-information-text {
    margin: 0 auto;
    text-align: center;
  }
}
@media screen and (max-width: 900px) {
  .customer-experience .customer-experience-container .customer-experience-info .customer-experience-information-text {
    font-size: 18px;
    line-height: 24px;
  }
}
@media screen and (max-width: 545px) {
  .customer-experience .customer-experience-container .customer-experience-info .customer-experience-information-text {
    font-size: 16px;
    line-height: 24px;
  }
}
.customer-experience .customer-experience-container .customer-experience-slider.swiper {
  overflow: unset;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .swiper-slide {
  width: auto;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card {
  width: 456px;
  height: 288px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  padding: 24px;
}
@media screen and (max-width: 900px) {
  .customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card {
    width: 404px;
    height: 243px;
  }
}
@media screen and (max-width: 545px) {
  .customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card {
    width: 280px;
    height: 340px;
  }
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card.long-card {
  width: 936px;
}
@media screen and (max-width: 900px) {
  .customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card.long-card {
    width: 705px;
  }
}
@media screen and (max-width: 545px) {
  .customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card.long-card {
    width: 420px;
  }
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card.long-card .customer-experience-card-content .customer-experience-description {
  max-width: 888px;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card .customer-experience-card-content .quotes {
  max-width: 34px;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card .customer-experience-card-content .customer-experience-card-heading {
  margin-top: 16px;
  font-size: 24px;
  line-height: 32px;
}
@media screen and (max-width: 900px) {
  .customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card .customer-experience-card-content .customer-experience-card-heading {
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen and (max-width: 545px) {
  .customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card .customer-experience-card-content .customer-experience-card-heading {
    font-size: 18px;
    line-height: 24px;
  }
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card .customer-experience-card-content .customer-experience-description {
  margin-top: 8px;
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (max-width: 900px) {
  .customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card .customer-experience-card-content .customer-experience-description {
    font-size: 14px;
    line-height: 20px;
  }
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer-experience-card .card-triangle {
  position: absolute;
  bottom: -38px;
  left: 7px;
  width: 48px;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer {
  margin-top: 24px;
  display: flex;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-image {
  background-color: white;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
  display: flex;
  justify-content: center;
  align-items: center;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-image.google-customer {
  padding-left: 5px;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-image.google-customer svg {
  width: 25px;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-image.instagram-customer {
  background: radial-gradient(179.06% 43.44% at -16.75% 7.2%, #3771C8 0%, #3771C8 12.8%, rgba(102, 0, 255, 0) 100%), radial-gradient(92.18% 99.11% at 26.56% 107.7%, #FFDD55 0%, #FFDD55 10%, #FF543E 50%, #C837AB 100%);
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-image.instagram-customer svg {
  width: 24px;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-image.twitter-customer {
  color: white;
  background-color: #41ABE1;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-image.twitter-customer svg {
  width: 31px;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-image.no-avatar {
  background-color: #FF9911;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-image.no-avatar svg {
  width: 28px;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-info {
  margin-left: 24px;
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-info .customer-name {
  font-size: 24px;
  line-height: 32px;
}
@media screen and (max-width: 900px) {
  .customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-info .customer-name {
    font-size: 20px;
    line-height: 24px;
  }
}
@media screen and (max-width: 545px) {
  .customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-info .customer-name {
    font-size: 18px;
    line-height: 24px;
  }
}
.customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-info .date-review {
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (max-width: 900px) {
  .customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-info .date-review {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 900px) {
  .customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-info .date-review {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 545px) {
  .customer-experience .customer-experience-container .customer-experience-slider .swiper-wrapper .customer-review .customer .customer-info .date-review {
    font-size: 12px;
    line-height: 16px;
  }
}

.supported-languages {
  margin-bottom: 176px;
}
@media screen and (max-width: 545px) {
  .supported-languages {
    margin-bottom: 104px;
  }
}
.supported-languages .supported-languages-container .supported-languages-heading {
  text-align: center;
  margin-bottom: 16px;
}
.supported-languages .supported-languages-container .supported-languages-voice-track {
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
  max-width: unset;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
}
@media screen and (max-width: 900px) {
  .supported-languages .supported-languages-container .supported-languages-voice-track {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 545px) {
  .supported-languages .supported-languages-container .supported-languages-voice-track {
    font-size: 16px;
    line-height: 24px;
  }
}
.supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px 0;
  column-gap: 31px;
}
@media screen and (max-width: 1450px) {
  .supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards {
    justify-content: center;
  }
}
@media screen and (max-width: 900px) {
  .supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards {
    gap: 16px;
  }
}
.supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card {
  height: 152px;
  padding: 0 48px 0 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7D8488;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
@media screen and (max-width: 1450px) {
  .supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 1145px) {
  .supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card {
    min-width: 344px;
    max-width: 368px;
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card {
    max-width: 344px;
    margin-bottom: unset;
  }
}
@media screen and (max-width: 784px) {
  .supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card {
    width: unset;
  }
}
@media screen and (max-width: 545px) {
  .supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card {
    min-width: unset;
    max-width: 344px;
    width: 100%;
    padding: unset;
  }
}
@media screen and (max-width: 1145px) {
  .supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card.central-card {
    min-width: 704px;
    max-width: 767px;
    width: 100%;
  }
}
@media screen and (max-width: 860px) {
  .supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card.central-card {
    max-width: 751px;
  }
}
@media screen and (max-width: 784px) {
  .supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card.central-card {
    width: unset;
  }
}
@media screen and (max-width: 767px) {
  .supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card.central-card {
    min-width: 344px;
  }
}
@media screen and (max-width: 545px) {
  .supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card.central-card {
    min-width: unset;
    max-width: 344px;
    padding: unset;
    width: 100%;
  }
}
.supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card .card-image {
  margin-top: 25px;
  margin-bottom: 10px;
}
.supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card .card-image.english {
  width: 28px;
}
.supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card .card-image.spanish {
  width: 56px;
}
.supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card .card-image.french {
  width: 37px;
}
.supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card .card-image.russian {
  width: 52px;
}
.supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card .card-image.deutsch {
  width: 55px;
}
.supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card .language-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card .language-block .language {
  text-align: center;
  font-family: OpenSans, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #263238;
  margin-bottom: 16px;
}
.supported-languages .supported-languages-container .supported-languages-cards-block .supported-languages-cards .supported-languages-card .language-block .get-started {
  font-size: 14px;
  line-height: 16px;
  font-weight: 800;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  color: #FF9911;
}

.become-affiliate .become-affiliate-container .become-affiliate-content {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  position: relative;
}
.become-affiliate .become-affiliate-container .become-affiliate-content:after {
  background: linear-gradient(100.74deg, #FFFFFF 37.78%, rgba(255, 240, 219, 0.951811) 59.19%, rgba(255, 217, 167, 0.88129) 71.56%, rgba(255, 153, 17, 0.68) 100%);
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
}
@media screen and (max-width: 900px) {
  .become-affiliate .become-affiliate-container .become-affiliate-content:after {
    opacity: 0.6;
  }
}
@media screen and (max-width: 1200px) {
  .become-affiliate .become-affiliate-container .become-affiliate-content {
    margin-left: 24px;
  }
}
.become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text {
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1200px) {
  .become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text {
    margin-right: 24px;
  }
}
.become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-heading {
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: #0077DD;
  margin-top: 72px;
}
@media screen and (max-width: 1200px) {
  .become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-heading {
    margin-top: 48px;
  }
}
.become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-text {
  margin-top: 16px;
}
@media screen and (max-width: 900px) {
  .become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-text {
    font-size: 36px;
    line-height: 40px;
  }
}
.become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-text .become-affiliate-logo {
  max-width: 170px;
}
.become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-commission {
  margin-top: 8px;
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
  max-width: 696px;
}
@media screen and (max-width: 900px) {
  .become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-commission {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 900px) {
  .become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-commission {
    font-size: 16px;
    line-height: 24px;
  }
}
.become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-button {
  margin-top: 40px;
  display: inline-block;
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: white;
  text-transform: uppercase;
  padding: 12px 16px;
  background: #0077DD;
  box-shadow: 0 1px 3px rgba(0, 119, 221, 0.4), 0 3px 6px rgba(0, 119, 221, 0.2);
  border-radius: 24px;
  transition: box-shadow ease 200ms;
}
.become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-button:hover {
  box-shadow: 0 1px 3px rgba(0, 119, 221, 0.6), 0 3px 6px rgba(0, 119, 221, 0.6);
}
.become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-description {
  margin-top: 16px;
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
  font-size: 16px;
  line-height: 24px;
  max-width: 696px;
  opacity: 0.6;
}
@media screen and (max-width: 900px) {
  .become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-description {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .become-affiliate .become-affiliate-container .become-affiliate-content .become-affiliate-information-text .become-affiliate-description {
    margin-bottom: 24px;
  }
}
.become-affiliate .become-affiliate-container .become-affiliate-image-block {
  position: relative;
  z-index: 2;
}
.become-affiliate .become-affiliate-container .become-affiliate-image-block .become-affiliate-image {
  max-width: 659px;
  height: 100%;
  border-radius: 0 8px 8px 0;
}
@media screen and (max-width: 1200px) {
  .become-affiliate .become-affiliate-container .become-affiliate-image-block .become-affiliate-image {
    display: none;
  }
}

.experience-promptsmart-difference {
  margin-top: 200px;
  margin-bottom: 176px;
}
@media screen and (max-width: 900px) {
  .experience-promptsmart-difference {
    margin: 176px 0;
  }
}
@media screen and (max-width: 545px) {
  .experience-promptsmart-difference {
    margin: 104px 0;
  }
}
.experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-text {
  text-align: center;
}
.experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-text .experience-promptsmart-difference-description {
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
  max-width: 816px;
  margin: 16px auto 32px;
}
@media screen and (max-width: 900px) {
  .experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-text .experience-promptsmart-difference-description {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 900px) {
  .experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-text .experience-promptsmart-difference-description {
    font-size: 18px;
    max-width: 703px;
    line-height: 24px;
  }
}
.experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 736px) {
  .experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card {
  background: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 458px;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 736px) {
  .experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card {
    max-width: 353px;
  }
}
.experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card:not(:last-child) {
  margin-right: 24px;
}
@media screen and (max-width: 900px) {
  .experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card:not(:last-child) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 736px) {
  .experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card:not(:last-child) {
    margin: 0 0 16px;
  }
}
.experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card .card-platforms {
  display: flex;
  justify-content: center;
}
.experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card .card-platforms .platform {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: rgba(38, 50, 56, 0.4);
  text-transform: uppercase;
}
@media screen and (max-width: 900px) {
  .experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card .card-platforms .platform {
    font-size: 14px;
    line-height: 16px;
  }
}
.experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card .card-platforms .platform:not(:last-child, :first-child) {
  border-right: 1px solid #C4C4C4;
  border-left: 1px solid #C4C4C4;
  padding: 0 10px;
  margin: 0 10px;
}
.experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card .card-heading {
  margin-top: 16px;
  font-size: 24px;
  line-height: 32px;
  color: black;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card .card-heading {
    font-size: 20px;
    line-height: 24px;
  }
}
.experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card .experience-promptsmart-difference-card-description {
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
  max-width: 408px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 8px;
  color: #444444;
  min-height: 72px;
  margin-bottom: 24px;
}
@media screen and (max-width: 900px) {
  .experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card .experience-promptsmart-difference-card-description {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 900px) {
  .experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card .experience-promptsmart-difference-card-description {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 736px) {
  .experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card .experience-promptsmart-difference-card-description {
    min-height: 60px;
    margin-bottom: 36px;
  }
}
.experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card .learn-more-button {
  display: inline-block;
  text-align: center;
  padding: 10px 16px;
  border-radius: 24px;
  border: 1px solid rgba(38, 50, 56, 0.4);
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #FF9911;
  text-transform: uppercase;
  margin: auto auto 0 auto;
  transition: all ease 200ms;
}
.experience-promptsmart-difference .experience-promptsmart-difference-container .experience-promptsmart-difference-cards .experience-promptsmart-difference-card .learn-more-button:hover {
  background: rgba(255, 153, 17, 0.12);
  border: 1px solid rgba(38, 50, 56, 0.2);
}

.footer-container .footer-content {
  padding-bottom: 49px;
  border-bottom: 1px solid rgba(38, 50, 56, 0.4);
}
@media screen and (max-width: 650px) {
  .footer-container .footer-content {
    padding-bottom: 16px;
  }
}
.footer-container .footer-content .footer-links-list {
  display: flex;
}
@media screen and (max-width: 1340px) {
  .footer-container .footer-content .footer-links-list {
    justify-content: space-between;
  }
}
@media screen and (max-width: 650px) {
  .footer-container .footer-content .footer-links-list {
    justify-content: unset;
    flex-direction: column;
  }
}
@media screen and (max-width: 1105px) {
  .footer-container .footer-content .footer-links-list .footer-logo img {
    max-width: 175px;
  }
}
@media screen and (max-width: 900px) {
  .footer-container .footer-content .footer-links-list .footer-logo img {
    max-width: 136px;
  }
}
.footer-container .footer-content .footer-links-list .footer-logo .download-links {
  margin: 24px 0 8px;
}
@media (min-width: 901px) {
  .footer-container .footer-content .footer-links-list .footer-logo .download-links {
    margin-top: 40px;
  }
}
@media (min-width: 650px) {
  .footer-container .footer-content .footer-links-list .footer-logo .download-links {
    flex-direction: column;
    gap: 12px;
  }
}
.footer-container .footer-content .footer-links-list .footer-logo .download-links a.app-store-link {
  width: 120px;
}
@media (min-width: 901px) {
  .footer-container .footer-content .footer-links-list .footer-logo .download-links a.app-store-link {
    width: 160px;
  }
}
.footer-container .footer-content .footer-links-list .footer-logo .download-links a.google-play-link {
  width: 135px;
}
@media (min-width: 901px) {
  .footer-container .footer-content .footer-links-list .footer-logo .download-links a.google-play-link {
    width: 183px;
  }
}
.footer-container .footer-content .footer-links-list .footer-pricing {
  display: flex;
  flex-direction: column;
  padding-right: 95px;
  margin-right: 96px;
  box-sizing: unset;
  border-right: 1px solid rgba(38, 50, 56, 0.4);
}
@media screen and (max-width: 1340px) {
  .footer-container .footer-content .footer-links-list .footer-pricing {
    margin-right: 49px;
  }
}
@media screen and (max-width: 1150px) {
  .footer-container .footer-content .footer-links-list .footer-pricing {
    padding-right: 50px;
  }
}
@media screen and (max-width: 1051px) {
  .footer-container .footer-content .footer-links-list .footer-pricing {
    max-width: 100px;
  }
}
@media screen and (max-width: 900px) {
  .footer-container .footer-content .footer-links-list .footer-pricing {
    padding-right: 0;
    margin-right: 28px;
    max-width: unset;
  }
}
@media screen and (max-width: 705px) {
  .footer-container .footer-content .footer-links-list .footer-pricing {
    max-width: 90px;
  }
}
@media screen and (max-width: 650px) {
  .footer-container .footer-content .footer-links-list .footer-pricing {
    border-right: unset;
    padding: 0 0 16px 0;
    margin: 0 0 16px 0;
    border-bottom: 1px solid rgba(38, 50, 56, 0.4);
    max-width: unset;
  }
}
.footer-container .footer-content .footer-links-list .footer-pricing .link:not(:last-child) {
  margin-bottom: 24px;
}
@media screen and (max-width: 650px) {
  .footer-container .footer-content .footer-links-list .footer-pricing .link:not(:last-child) {
    margin-bottom: 16px;
  }
}
.footer-container .footer-content .footer-links-list .footer-privacy-policy .privacy-policy-types {
  margin-top: 16px;
}
@media screen and (max-width: 1305px) {
  .footer-container .footer-content .footer-links-list .footer-privacy-policy .privacy-policy-types {
    margin-top: 12px;
  }
}
.footer-container .footer-content .footer-links-list .footer-privacy-policy .privacy-policy-types a {
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
  font-size: 16px;
  line-height: 24px;
  max-width: unset;
  display: inline-block;
}
@media screen and (max-width: 900px) {
  .footer-container .footer-content .footer-links-list .footer-privacy-policy .privacy-policy-types a {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 1175px) {
  .footer-container .footer-content .footer-links-list .footer-privacy-policy .privacy-policy-types a {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 900px) {
  .footer-container .footer-content .footer-links-list .footer-privacy-policy .privacy-policy-types a {
    font-size: 12px;
  }
}
.footer-container .footer-content .footer-links-list .footer-privacy-policy .privacy-policy-types a:not(:last-child) {
  margin-bottom: 16px;
}
@media screen and (max-width: 1305px) {
  .footer-container .footer-content .footer-links-list .footer-privacy-policy .privacy-policy-types a:not(:last-child) {
    margin-bottom: 12px;
  }
}
.footer-container .copyright-social-media {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 545px) {
  .footer-container .copyright-social-media {
    justify-content: unset;
    flex-direction: column;
  }
}
.footer-container .copyright-social-media .copyrights {
  font-size: 24px;
  line-height: 32px;
  font-family: OpenSans, sans-serif;
  max-width: 664px;
  width: 100%;
  color: #263238;
  font-size: 16px;
  line-height: 24px;
  max-width: unset;
}
@media screen and (max-width: 900px) {
  .footer-container .copyright-social-media .copyrights {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 545px) {
  .footer-container .copyright-social-media .copyrights {
    margin-bottom: 16px;
  }
}
.footer-container .copyright-social-media .social-links {
  display: flex;
  align-items: center;
}
.footer-container .copyright-social-media .social-links a {
  color: #263238;
}
.footer-container .copyright-social-media .social-links a:not(:last-child) {
  margin-right: 26px;
}

.footer-link {
  max-width: 216px;
  width: 100%;
  height: 224px;
  display: flex;
  flex-direction: column;
}
.footer-link.footer-product a:not(:last-child) {
  margin-bottom: 24px;
}
@media screen and (max-width: 650px) {
  .footer-link.footer-product a:not(:last-child) {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 960px) {
  .footer-link {
    height: 200px;
  }
}
@media screen and (max-width: 900px) {
  .footer-link {
    height: 136px;
  }
}
@media screen and (max-width: 650px) {
  .footer-link {
    height: unset;
    max-width: unset;
  }
}
.footer-link .link {
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
}
@media screen and (max-width: 900px) {
  .footer-link .link {
    font-size: 12px;
    line-height: 16px;
  }
}
@media screen and (max-width: 650px) {
  .footer-link .link {
    font-size: 14px;
  }
}
.footer-link:not(:last-child) {
  margin-right: 192px;
}
@media screen and (max-width: 1340px) {
  .footer-link:not(:last-child) {
    margin-right: 99px;
  }
}
@media screen and (max-width: 960px) {
  .footer-link:not(:last-child) {
    margin-right: 44px;
  }
}
@media screen and (max-width: 900px) {
  .footer-link:not(:last-child) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 650px) {
  .footer-link:not(:last-child) {
    margin: 0 0 16px 0;
  }
}

.get-more {
  margin-top: 104px;
}
@media (min-width: 545px) {
  .get-more {
    margin-top: 176px;
  }
}
@media (min-width: 901px) {
  .get-more {
    margin-top: 200px;
  }
}
.get-more-container .get-more-content-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 38px;
}
@media (min-width: 545px) {
  .get-more-container .get-more-content-wrapper {
    gap: 58px;
  }
}
@media (min-width: 1235px) {
  .get-more-container .get-more-content-wrapper {
    gap: 24px;
    flex-wrap: nowrap;
    justify-content: unset;
  }
}
.get-more-container .get-more-content-wrapper .get-more-image-block {
  width: 100%;
}
@media (min-width: 901px) {
  .get-more-container .get-more-content-wrapper .get-more-image-block {
    width: unset;
  }
}
.get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper {
  background: linear-gradient(90deg, #EAEBEC 0%, rgba(234, 235, 236, 0) 100%);
  border-radius: 16px;
  max-width: 704px;
  height: 138px;
  position: relative;
}
@media (min-width: 545px) {
  .get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper {
    height: 282px;
  }
}
@media (min-width: 901px) {
  .get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper {
    max-width: unset;
    width: 816px;
    height: 328px;
  }
}
.get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .leaflet {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 16px;
  width: 90px;
}
@media (min-width: 395px) {
  .get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .leaflet {
    width: 120px;
  }
}
@media (min-width: 545px) {
  .get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .leaflet {
    width: 210px;
  }
}
@media (min-width: 901px) {
  .get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .leaflet {
    width: unset;
  }
}
.get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .phones-image {
  position: absolute;
  right: 56px;
  top: -18px;
  display: flex;
  gap: 24px;
}
@media (min-width: 545px) {
  .get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .phones-image {
    top: -36px;
    gap: 40px;
  }
}
@media (min-width: 901px) {
  .get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .phones-image {
    top: -42px;
  }
}
.get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .phones-image .first-phone-image {
  width: 84px;
}
@media (min-width: 545px) {
  .get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .phones-image .first-phone-image {
    width: 170px;
  }
}
@media (min-width: 901px) {
  .get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .phones-image .first-phone-image {
    width: 198px;
  }
}
.get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .phones-image .second-phone-image {
  width: 78px;
}
@media (min-width: 545px) {
  .get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .phones-image .second-phone-image {
    width: 160px;
  }
}
@media (min-width: 901px) {
  .get-more-container .get-more-content-wrapper .get-more-image-block .image-wrapper .phones-image .second-phone-image {
    width: 186px;
  }
}
.get-more-container .get-more-content-wrapper .get-more-links {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
@media (min-width: 901px) {
  .get-more-container .get-more-content-wrapper .get-more-links {
    gap: 32px;
  }
}
@media (min-width: 1235px) {
  .get-more-container .get-more-content-wrapper .get-more-links {
    margin: 0;
    max-width: 576px;
    text-align: left;
    align-items: flex-start;
  }
}