@use "/src/styles/mixins" as mixin;
@use "/src/styles/constants" as constant;

.experience-promptsmart-difference {
  margin-top: 200px;
  margin-bottom: 176px;

  @media screen and (max-width: constant.$tablet-size) {
    margin: 176px 0;
  }

  @media screen and (max-width: constant.$mobile-size) {
    margin: 104px 0;
  }

  .experience-promptsmart-difference-container {
    .experience-promptsmart-difference-text {
      text-align: center;

      .experience-promptsmart-difference-description {
        @include mixin.description;
        max-width: 816px;
        margin: 16px auto 32px;

        @media screen and (max-width: constant.$tablet-size) {
          font-size: 18px;
          max-width: 703px;
          line-height: 24px;
        }
      }
    }

    .experience-promptsmart-difference-cards {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 736px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      .experience-promptsmart-difference-card {
        background: #FFFFFF;
        box-shadow: 0 4px 8px rgba(black, 0.1), 0 8px 16px rgba(black, 0.1);
        border-radius: 8px;
        max-width: 458px;
        padding: 24px;
        width: 100%;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 736px) {
          max-width: 353px;
        }

        &:not(:last-child) {
          margin-right: 24px;

          @media screen and (max-width: constant.$tablet-size) {
            margin-right: 16px;
          }

          @media screen and (max-width: 736px) {
            margin: 0 0 16px;
          }
        }

        .card-platforms {
          display: flex;
          justify-content: center;

          .platform {
            font-family: Montserrat, sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 16px;
            color: rgba(constant.$main-dark, .4);
            text-transform: uppercase;

            @media screen and (max-width: constant.$tablet-size) {
              font-size: 14px;
              line-height: 16px;
            }

            &:not(:last-child,:first-child) {
              border-right: 1px solid constant.$border-color;
              border-left: 1px solid constant.$border-color;
              padding: 0 10px;
              margin: 0 10px;
            }
          }
        }

        .card-heading {
          margin-top: 16px;
          font-size: 24px;
          line-height: 32px;
          color: black;
          text-align: center;

          @media screen and (max-width: constant.$tablet-size) {
            font-size: 20px;
            line-height: 24px;
          }

          @media screen and (max-width: constant.$mobile-size) {

          }
        }

        .experience-promptsmart-difference-card-description {
          @include mixin.description;
          max-width: 408px;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          margin-top: 8px;
          color: #444444;
          min-height: 72px;
          margin-bottom: 24px;

          @media screen and (max-width: constant.$tablet-size) {
            margin-bottom: 50px;
          }

          @media screen and (max-width: 736px) {
            min-height: 60px;
            margin-bottom: 36px;
          }
        }

        .learn-more-button {
          display: inline-block;
          text-align: center;
          padding: 10px 16px;
          border-radius: 24px;
          border: 1px solid rgba(constant.$main-dark, 0.4);
          font-family: Montserrat, sans-serif;
          font-weight: 800;
          font-size: 14px;
          line-height: 16px;
          color: constant.$main-orange;
          text-transform: uppercase;
          margin: auto auto 0 auto;
          transition: all ease 200ms;

          &:hover {
            background: rgba(constant.$main-orange, .12);
            border: 1px solid rgba(constant.$main-dark, .2);
          }
        }
      }
    }
  }
}
