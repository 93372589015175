@use "/src/styles/constants" as constant;
@use "/src/styles/mixins" as mixin;

.footer-container {
  .footer-content {
    padding-bottom: 49px;
    border-bottom: 1px solid rgba(constant.$main-dark, 0.4);

    @media screen and (max-width: 650px) {
      padding-bottom: 16px;
    }

    .footer-links-list {
      display: flex;

      @media screen and (max-width: 1340px) {
        justify-content: space-between;
      }

      @media screen and (max-width: 650px) {
        justify-content: unset;
        flex-direction: column;
      }

      .footer-logo {
        img {
          @media screen and (max-width: 1105px) {
            max-width: 175px;
          }

          @media screen and (max-width: constant.$tablet-size) {
            max-width: 136px;
          }
        }

        .download-links {
          margin: 24px 0 8px;
          @media (min-width: 901px) {
            margin-top: 40px;
          }
          @media (min-width: 650px) {
            flex-direction: column;
            gap: 12px;
          }

          a {
            &.app-store-link {
              width: 120px;
              @media (min-width: 901px) {
                width: 160px;
              }
            }

            &.google-play-link {
              width: 135px;
              @media (min-width: 901px) {
                width: 183px;
              }
            }
          }
        }
      }

      .footer-pricing {
        display: flex;
        flex-direction: column;
        padding-right: 95px;
        margin-right: 96px;
        box-sizing: unset;
        border-right: 1px solid rgba(constant.$main-dark, 0.4);

        @media screen and (max-width: 1340px) {
          margin-right: 49px;
        }

        @media screen and (max-width: 1150px) {
          padding-right: 50px;
        }

        @media screen and (max-width: 1051px) {
          max-width: 100px;
        }

        @media screen and (max-width: constant.$tablet-size) {
          padding-right: 0;
          margin-right: 28px;
          max-width: unset;
        }

        @media screen and (max-width: 705px) {
          max-width: 90px;
        }

        @media screen and (max-width: 650px) {
          border-right: unset;
          padding: 0 0 16px 0;
          margin: 0 0 16px 0;
          border-bottom: 1px solid rgba(constant.$main-dark, 0.4);
          max-width: unset;
        }

        .link {
          &:not(:last-child) {
            margin-bottom: 24px;

            @media screen and (max-width: 650px) {
              margin-bottom: 16px;
            }
          }
        }
      }

      .footer-privacy-policy {
        .privacy-policy-types {
          margin-top: 16px;

          @media screen and (max-width: 1305px) {
            margin-top: 12px;
          }

          a {
            @include mixin.description;
            font-size: 16px;
            line-height: 24px;
            max-width: unset;
            display: inline-block;

            @media screen and (max-width: 1175px) {
              font-size: 14px;
              line-height: 20px;
            }

            @media screen and (max-width: constant.$tablet-size) {
              font-size: 12px;
            }

            &:not(:last-child) {
              margin-bottom: 16px;

              @media screen and (max-width: 1305px) {
                margin-bottom: 12px;
              }
            }
          }
        }
      }
    }
  }

  .copyright-social-media {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: constant.$mobile-size) {
      justify-content: unset;
      flex-direction: column;
    }

    .copyrights {
      @include mixin.description;
      font-size: 16px;
      line-height: 24px;
      max-width: unset;

      @media screen and (max-width: constant.$mobile-size) {
        margin-bottom: 16px;
      }
    }

    .social-links {
      display: flex;
      align-items: center;

      a {
        color: constant.$main-dark;

        &:not(:last-child) {
          margin-right: 26px;
        }
      }
    }
  }
}

.footer-link {
  max-width: 216px;
  width: 100%;
  height: 224px;
  display: flex;
  flex-direction: column;

  &.footer-product {
    a {
      &:not(:last-child) {
        margin-bottom: 24px;
        @media screen and (max-width: 650px) {
          margin-bottom: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    height: 200px;
  }

  @media screen and (max-width: constant.$tablet-size) {
    height: 136px;
  }

  @media screen and (max-width: 650px) {
    height: unset;
    max-width: unset;
  }

  .link {
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;

    @media screen and (max-width: constant.$tablet-size) {
      font-size: 12px;
      line-height: 16px;
    }

    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
  }

  &:not(:last-child) {
    margin-right: 192px;

    @media screen and (max-width: 1340px) {
      margin-right: 99px;
    }

    @media screen and (max-width: 960px) {
      margin-right: 44px;
    }

    @media screen and (max-width: constant.$tablet-size) {
      margin-right: 16px;
    }

    @media screen and (max-width: 650px) {
      margin: 0 0 16px 0;
    }
  }
}
