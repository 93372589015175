@use '/src/styles/mixins' as mixin;
@use '/src/styles/constants' as constant;

.supported-languages {
  margin-bottom: 176px;

  @media screen and (max-width: constant.$mobile-size) {
    margin-bottom: 104px;
  }

  .supported-languages-container {
    .supported-languages-heading {
      text-align: center;
      margin-bottom: 16px;
    }

    .supported-languages-voice-track {
      @include mixin.description;
      max-width: unset;
      text-align: center;
      font-size: 24px;
      line-height: 32px;

      @media screen and (max-width: constant.$mobile-size) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .supported-languages-cards-block {
      .supported-languages-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 24px 0;
        column-gap: 31px;

        @media screen and (max-width: 1450px) {
          justify-content: center;
        }

        @media screen and (max-width: constant.$tablet-size) {
          gap: 16px;
        }

        .supported-languages-card {
          height: 152px;
          padding: 0 48px 0 48px;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #7D8488;
          box-shadow: 0 4px 8px rgba(black, 0.1), 0 8px 16px rgba(black, 0.1);
          border-radius: 8px;

          @media screen and (max-width: 1450px) {
            margin-bottom: 32px;
          }

          @media screen and (max-width: 1145px) {
            min-width: 344px;
            max-width: 368px;
            width: 100%;
          }

          @media screen and (max-width: constant.$tablet-size) {
            max-width: 344px;
            margin-bottom: unset;
          }

          @media screen and (max-width: 784px) {
            width: unset;
          }

          @media screen and (max-width: constant.$mobile-size) {
            min-width: unset;
            max-width: 344px;
            width: 100%;
            padding: unset;
          }

          &.central-card {
            @media screen and (max-width: 1145px) {
              min-width: 704px;
              max-width: 767px;
              width: 100%;
            }

            @media screen and (max-width: 860px) {
              max-width: 751px;
            }

            @media screen and (max-width: 784px) {
              width: unset;
            }

            @media screen and (max-width: 767px) {
              min-width: 344px;
            }

            @media screen and (max-width: constant.$mobile-size) {
              min-width: unset;
              max-width: 344px;
              padding: unset;
              width: 100%;
            }
          }

          .card-image {
            margin-top: 25px;
            margin-bottom: 10px;

            &.english {
              width: 28px;
            }

            &.spanish {
              width: 56px;
            }

            &.french {
              width: 37px;
            }

            &.russian {
              width: 52px;
            }

            &.deutsch {
              width: 55px;
            }
          }

          .language-block {
            display: flex;
            flex-direction: column;
            align-items: center;

            .language {
              text-align: center;
              font-family: OpenSans, sans-serif;
              font-style: normal;
              font-weight: 800;
              font-size: 12px;
              line-height: 15px;
              text-transform: uppercase;
              color: constant.$main-dark;
              margin-bottom: 16px;
            }

            .get-started {
              font-size: 14px;
              line-height: 16px;
              font-weight: 800;
              text-transform: uppercase;
              font-family: Montserrat, sans-serif;
              color: constant.$main-orange;
            }
          }
        }
      }
    }
  }
}
